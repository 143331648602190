@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Montserrat:wght@400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

:root {
  --secondary-color: #12b8ff;
  --header-text-color: #000;
  --light-text-color: #eee;
  --dark-text-color: #000;
}
