.container {
  display: flex;
  height: calc(100vh - 150px);
}
.content {
  width: 65%;
  margin: auto;
}
.content h1 {
  font-size: 4rem;
  padding-bottom: 6rem;
  text-decoration: underline;
  text-decoration-color: var(--secondary-color);
}
.innerContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.callToAction {
  flex-grow: 1;
  text-align: center;
  margin: auto;
  font-size: 1.2rem;
}
.contactForm {
  flex-basis: 20rem;
  flex-grow: 1;
  padding: 1rem;
  border-radius: 5px;
  /* border: 1px solid var(--secondary-color); */
}
.contactIcons {
  padding: 2rem 0;
}
.icons {
  font-size: 3rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}
a {
  color: #000;
}
.icons:hover {
  background-color: var(--secondary-color);
  /* color: var(--secondary-color); */
  border-radius: 5px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
form input,
textarea {
  border: none;
  border-radius: 5px;
  padding: 0.3rem;
  font-size: 1rem;
  background-color: #f8f9fa;
  resize: none;
}
form button {
  background-color: transparent;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem 0;
  border: 2px solid var(--secondary-color);
  cursor: pointer;
  transition: all 0.5s ease;
}

form button:hover {
  font-weight: bold;
  background-color: var(--secondary-color);
}

@media only screen and (max-width: 500px) {
  .content {
    width: 90%;
  }
  .content h1 {
    font-size: 2.8rem;
    font-weight: lighter;
    text-align: center;
    padding-bottom: 2rem;
  }
  .callToAction {
    margin-bottom: 2rem;
  }
}
