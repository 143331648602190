.container {
  height: 80px;
  background-color: rgb(24, 24, 24);
  display: flex;
  justify-content: center;
  align-items: center;
}
footer p {
  font-size: 1.2rem;
  color: white;
}
