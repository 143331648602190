.container {
  background-color: #eeecec;
}
.content {
  width: 85%;
  margin: auto;
  padding-top: 6rem;
  padding-bottom: 1rem;
}
.content h2 {
  font-size: 4rem;
  padding-bottom: 2rem;
  text-decoration: underline;
  text-decoration-color: var(--secondary-color);
}

.selectSkill button {
  /* font-weight: bold; */
  background-color: WHITE;
  border-radius: 10px;
  font-size: 1rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: 2px solid var(--secondary-color);
  cursor: pointer;
  transition: all 0.5s ease;
  position: relative;
}

.selectSkill button:hover {
  background-color: var(--secondary-color);
}

.selectSkill .active {
  background-color: var(--secondary-color);
}
.selectSkill .displayAmount {
  display: inline;
  position: absolute;
  background-color: white;
  width: 22px;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
  top: -10px;
  right: -9px;
}
.selectSkill .hideAmount {
  display: none;
}

@media only screen and (max-width: 500px) {
  .content {
    width: 90%;
    padding-top: 1.8rem;
  }
  .content h2 {
    font-size: 2.8rem;
    font-weight: lighter;
    text-align: center;
  }
  .selectSkill {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
