header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--secondary-color);
  color: var(--dark-text-color);
  position: fixed;
  width: 100%;
  z-index: 1;
}
header img {
  height: 80px;
}
nav {
  /* background-color: red; */
  vertical-align: middle;
  padding-bottom: 9px;
}
nav a {
  margin: 0 2rem;
  color: var(--dark-text-color);
  text-decoration: none;
  text-transform: uppercase;
}
nav a:hover {
  color: #132fc9;
}
header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--dark-text-color);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 800px) {
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--secondary-color);

    /* transition: 0.5s; */
    transform: translateY(-100vh);
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  header .responsive_nav {
    transform: none;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
  }
  nav a {
    font-size: 1.5rem;
  }
}
