.container {
  background-color: white;
  height: 100vh;
}
.content {
  width: 65%;
  margin: auto;
  padding-top: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.content h2 {
  font-size: 4rem;
  padding-bottom: 2rem;
  text-decoration: underline;
  text-decoration-color: var(--secondary-color);
}

.textContent {
  font-size: 1.2rem;
}
.skillsContainer {
  width: 40vw;
  background-color: #fefefe;
  border: 1px solid rgba(18, 184, 255, 0.2);
  margin: 3rem auto;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(18, 184, 255, 0.2);
}
.skillsTable {
  display: flex;
  justify-content: space-between;
}
.skillsTable ul {
  list-style: none;
  line-height: 1.8rem;
}
.blockquote {
  font-size: 1.2rem;
  text-align: center;
  line-height: 2rem;
}

@media only screen and (max-width: 500px) {
  .content {
    width: 90%;
    padding-top: 4.2rem;
  }
  .skillsContainer {
    width: 85vw;
  }
  h2 {
    font-size: 2.8rem;
    font-weight: lighter;
  }
  .blockquote {
    display: none;
  }
}
