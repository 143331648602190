.container {
  padding: 1rem 0;
  margin: 1rem 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;
}
.innerContainer {
  /* flex-basis: 25rem; */
  width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
  text-align: center;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}
.innerContainer h3 {
  margin: 1rem;
}
.innerContainer p {
  text-align: left;
  margin: 0 1rem;
}
.innerContainer img {
  /* width: 100%; */
  height: 320px;
  border-radius: 0.5rem 0.5rem 0 0;
  object-fit: cover;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.iconsContainer {
  margin: 1rem 0;
}
.icons {
  font-size: 1.8rem;
  /* padding: 0.1rem; */
  padding-right: 0.2rem;
  cursor: pointer;
  transition: all 0.5s ease;
  border: 2px solid transparent;
}
a {
  color: #000;
  text-decoration: none;
}
.projectsBtn {
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  border: 2px solid gray;
  border-radius: 5px;
  margin: 0px 5px;
  padding: 0.1rem 0.3rem;
  cursor: pointer;
}

.projectsBtn:hover {
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
}
/* .mobileIcons {
  display: none;
} */
.viewProjectEye {
  display: none;
}
@media only screen and (max-width: 500px) {
  .container {
    gap: 2rem;
  }
  .innerContainer {
    width: 370px;
  }
  .innerContainer img {
    height: 280px;
  }
  .desktopButton {
    display: none;
  }
  .viewProjectEye {
    display: inline-flex;
  }
}
.comingSoonCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 4vw;
  font-weight: 800;
  color: rgb(211, 207, 207);
  padding: 4rem 0;
}
